import React from "react"
import { LocalizedLink } from "gatsby-theme-i18n"
import { rgba } from "polished"
import { Container, Row, Col } from "react-bootstrap"

import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt"

import { Title, Section, Text, Badge } from "../../components/Core"
import styled from "styled-components"
import { Trans } from "@lingui/macro"
import { useOpenrolesEN } from "../../hooks/useOpenRolesEN"
import { useOpenrolesDE } from "../../hooks/useOpenRolesDE"
import { useLocalization } from "gatsby-theme-i18n"

const Card = styled(LocalizedLink)`
  justify-content: flex-start;
  min-height: 100%;
  box-shadow: 0 2px 4px rgba(14, 86, 124, 0.17);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px;
  transition: 0.4s;
  border-radius: 10px;
  &:hover {
    box-shadow: 0 32px 84px rgba(14, 86, 124, 0.17);
    color: #0056b3 !important;
    text-decoration: none;
    .title {
      color: #0056b3 !important;
    }
  }
`

const Location = styled.span`
  color: #8c97ac;
  font-size: 16px;
  font-weight: 300;
  margin-right: 5px;
  display: flex;
  align-items: center;
`

const BadgeStyled = styled(Badge)`
  background-color: ${({ theme, bg }) => rgba(theme.colors[bg], 0.15)};
  color: ${({ theme, bg }) => theme.colors[bg]};
  border: none;
  font-weight: 300;
`

const RolesCard = ({
  color = "primary",
  title,
  location,
  tags,
  to,
  children,
  ...rest
}) => (
  <Card className="card-job top-only" to={to} {...rest}>
    <div>
      <div className="d-flex align-items-center mb-2">
        <Location>
          <FaMapMarkerAlt css={{ marginRight: 5 }} /> {location}
        </Location>
      </div>
      <div>
        {tags.map((tag) => (
          <BadgeStyled
            bg={color}
            key={tag}
            className="mr-2 mb-2"
            style={{ display: "inline-block" }}
          >
            {tag}
          </BadgeStyled>
        ))}
      </div>
    </div>
    <Title variant="card" className="title" fontWeight={500} mt={3}>
      {title}
    </Title>
  </Card>
)

const Roles = () => {
  const { locale } = useLocalization()
  const allMdxDE = useOpenrolesDE()
  const allMdxEN = useOpenrolesEN()
  const allMdx = locale === "de" ? allMdxDE : allMdxEN
  return (
    <>
      <Section bg="#f7f7fb">
        <Container>
          <Row className="justify-content-center">
            <Col lg="8" className="text-center">
              <div>
                <Title>
                  <Trans>Offene Stellen</Trans>
                </Title>
                <Text>
                  <Trans>
                    Wir bieten tolle Chancen für alle, die Interesse an der
                    Erforschung und Beratung für Künstliche Intelligenz haben.
                    Bei uns kannst Du Dich sowohl beruflich als auch persönlich
                    weiterentwickeln.
                  </Trans>
                </Text>
                <Text>
                  <Trans>
                    AMAI bietet Dir einen aufregenden, herausfordernden und
                    lohnenden Job mit Start-up-Spirit. Wir ermutigen jeden
                    unserer Mitarbeiter:innen zu lernen, zu experimentieren und sich
                    weiter zu entwickeln. Die Technologieregion Karlsruhe und
                    die Nähe zum KIT bieten Dir dazu das perfekte Umfeld.
                  </Trans>
                </Text>
              </div>
            </Col>
          </Row>
          <Row className="mt-lg-5">
            {allMdx.edges.map((edge) => (
              <Col lg="4" md="6" className="pt-4" key={edge.node.fields.slug}>
                <RolesCard
                  color="secondary"
                  title={edge.node.frontmatter.title}
                  tags={edge.node.frontmatter.tags}
                  location={edge.node.frontmatter.location}
                  to={edge.node.fields.slug}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Roles
